.schedule-bar-img {
  width: 100px;
}

.schedule-bar-typo {
  margin: 8px 0 0 0;
  height: fit-content;
}

.nav {
  text-transform: uppercase;
}

.schedule-bar {
  justify-content: center;
}
.rowstyle {
  padding: 8px;
  padding-left: 70px;
  color: white;
  font-weight: 300;
  font-size: 20px;
  text-align: center;
  height: 65px;
  overflow: hidden;
}
.ThemeColor1 {
  /*background-color: #cf1f24;*/
}

.navlist {
  list-style: none;
}
.navfirst {
  display: inline;
  padding: 16px;
  font-size: 16px;
}
.formhead {
  padding-left: 40px;
  margin-top: 35px;
}
.search-bar {
  display: inline-flex;
  border: 2px solid #ddd;
  padding: 0px;
  height: 46px;
  border-radius: 4px;
}
.form-control {
  /*height: 42px !important;*/
  /*width: 300px !important;*/
  border: none !important;
}
.form-ctl {
  color: #ddd !important;
}
.searchicon {
  width: 25px;
  height: 38px;
  border: none;
}
.searchicon {
  margin-top: 12px;
  margin-left: 7px;
}
.search-bar {
  display: inline-flex;
  border: 2px solid #ddd;
  padding: 0px;
  height: 46px;
  border-radius: 4px;
}
.form-ctl {
  color: #ddd !important;
}
.dropdown {
  position: relative;
  display: inline-block;
}
.dropbtn {
  background-color: #ffffff;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}
.dropdown:hover .dropbtn {
  background-color: #16a3b2;
}
.dropdown:hover .dropdown-content {
  display: block;
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.dropdown-content a {
  color: #ffffff;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.dropdown-content a:hover {
  background-color: #ddd;
}

.location-sub-menu-container {
  position: relative;
}

.location-sub-menu-container > .location-sub-menu {
  position: absolute;
  top: 0;
  left: 100%;
  display: none;
  list-style-type: none;
  background: white;
  padding: 8px 0;
  width: 100%;
  max-width: 160px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
}

.housecheck-header-container {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
}

.housecheck-header-container > .housecheck-header-bar-1 {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  background-color: #f7f7f7;
  padding: 6px 166px;
}

.housecheck-header-container > .housecheck-header-bar-1 a {
  text-decoration: none;
}

.housecheck-header-container > .housecheck-header-bar-1 p {
  color: #282828;
  font-family: var(--primary-font);
  font-size: 20px;
  letter-spacing: -0.5px;
  line-height: 28px;
  margin: 0;
  text-align: right;
  padding: 0 5px;
}

.housecheck-header-container > .housecheck-header-bar-2 {
  background-color: #ffffff;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 15px 166px;
}

.housecheck-header-container > .housecheck-header-bar-3 {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 17px 166px 16px 166px;
  background-color: #282828;
  background: linear-gradient(270deg, #494745 25%, #000000 100%);
  color: #FFFFFF;
  font-family: var(--primary-font);
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
}

.housecheck-header-container > .housecheck-header-bar-3 > span {
  font-weight: normal;
}

.housecheck-header-container > .housecheck-header-bar-3 > .vertical-hr {
  width: 1px;
  height: 47px;
  background: #ffffff;
  margin: 0 15px;
}

.location-sub-menu-container:hover > .location-sub-menu {
  display: block;
}

.location-sub-menu-li {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.location-sub-menu-li:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}

.header-menu-nav-bar-row {
  padding: 15px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.navlogo-container {
  width: fit-content;
  max-width: calc(100% - 55px);
  height: 70px;
  padding-right: 16px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.navlogo {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
}

.header-right-btns-container {
  width: fit-content;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
}

.header-right-btns {
  font-family: var(--primary-font);
  font-size: 14px;
  letter-spacing: -0.44px;
  line-height: 24px;
  text-align: center;
  outline: 0;
  border: 0;
  border-radius: 4px;
  padding: 0 16px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.header-right-btns:focus {
  outline: 0;
}

.header-right-btns.header-right-btn-1 {
  color: white;
  background: black;
}

.header-right-btns.header-right-btn-1 a {
  color: white;
}

.side-bar-open {
  position: absolute;
}

.header-right-btns.header-right-btn-2 {
  color: #040404;
  background: transparent;
}

.header-right-btns.header-right-btn-2 > span {
  margin-left: -5px;
}

.housecheck-header-bar-2 .header-menu {
  height: 35px;
}

.housecheck-header-bar-2 .header-search {
  height: 20px;
  margin-left: auto;
}

.login-dropdown-option:hover,
.login-dropdown-option:focus,
.login-dropdown-option:active {
  outline: 0;
  background-color: #e9ecef !important;
  color: #212529 !important;
  cursor: pointer;
}

@media (max-width: 320px) {
  .schedule-bar-typo {
    font-size: 10px;
  }
  .row-style,
  .rowstyle {
    margin-left: 0px !important;
    padding: 8px 0 8px 0px;
  }
  .container {
    padding: 0 !important;
  }
}
@media (max-width: 376px) {
  .dropbtn {
    margin-left: 10px;
    background-color: #16a3b2;
    margin-top: 3px;
    padding: 6px;
    color: #ffffff;
    border-radius: 4px;
  }
  .search-bar {
    width: 230px;
    height: 30px;
  }
  .search-bar {
    width: 230px;
    height: 30px;
  }

  .schedule-bar-typo {
    font-size: 11px;
  }
}
@media (max-width: 361px) {
  .search-bar {
    width: 200px;
  }
  .schedule-bar-typo {
    font-size: 11px;
  }
}

@media (min-width: 497px) {
  .housecheck-header-bar-2 .header-menu {
    display: none;
  }

  .housecheck-header-bar-2 .header-search {
    display: none;
  }
  .side-bar-open {
    display: none;
  }
  .side-bar-close {
    display: none;
  }
}
@media (max-width: 415px) {
  .dropdown-content {
    right: 0px;
    min-width: 25px;
    background-color: #16a3b2;
    color: #ffffff;
  }
  .dropbtn {
    background-color: #16a3b2;
    margin-top: 3px;
    padding: 8px;
    color: #ffffff;
    border-radius: 4px;
    /*margin-left: 30px;*/
  }
  .input-append {
    padding-left: 15px;
  }
  .search-bar {
    width: 265px;
    height: 32px;
  }
  .searchicon {
    margin-top: 5px;
    margin-left: auto;
  }
  .input-append {
    padding-left: 15px;
  }
  .form-control {
    width: 100% !important;
  }
  /*.form-control {*/
  /*  height: 24px !important;*/
  /*}*/
  .search-bar {
    width: 265px;
    height: 32px;
  }
  .formhead {
    margin-top: 0px;
    margin: auto;
    padding-left: 0;
    margin-bottom: 10px;
  }
  .rd {
    display: none !important;
    flex-flow: wrap;
    /*display: none;*/
  }
  .schedule-bar-typo {
    font-size: 12px;
  }
  .rowstyle {
    padding: 8px;
    padding-left: 8px;
    color: white;
    font-weight: 300;
    font-size: 10px;
    text-align: center;
    /* display: flex !important; */
    word-break: break-word;
    height: 65px;
    overflow: hidden;
  }
  .ThemeColor1 {
    background-color: #cf1f24;
  }
}
@media (max-width: 768px) {
  .search-bar {
    width: 260px;
  }
  .container {
    max-width: 100% !important;
    /*margin: 0 !important;*/
  }
  .search-bar {
    width: 260px;
  }
}
@media (max-width: 769px) {
  .search-bar {
    width: 235px;
  }
  .search-bar {
    width: 235px;
  }
  .formhead {
    width: 24%;
    margin-top: 18px;
    padding-left: 0px;
  }
  .navfirst {
    display: inline;
    padding: 5px;
    font-size: 14px;
  }
  .navlist {
    position: relative;
  }

  .schedule-bar-typo {
    display: flex;
    align-items: center;
  }
  .container {
    padding: 0;
    margin: 0 !important;
  }
}

@media (max-width: 1024px) {
  .housecheck-header-container > .housecheck-header-bar-1 {
    padding: 6px 100px;
  }
  .housecheck-header-container > .housecheck-header-bar-2 {
    padding: 0 100px;
  }

  .housecheck-header-container > .housecheck-header-bar-3 {
    padding: 6px 100px;
  }
}

@media (max-width: 768px) {
  .housecheck-header-container > .housecheck-header-bar-2 {
    padding: 0 50px;
  }

  .housecheck-header-container > .housecheck-header-bar-3 {
    padding: 6px 50px;
  }

  .housecheck-header-container > .housecheck-header-bar-1 {
    justify-content: center;
    padding: 6px 50px;
  }

  .header-right-btns-container {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 480px) {
  .housecheck-header-container > .housecheck-header-bar-1 {
    padding: 10px 25px;
  }
  .housecheck-header-container > .housecheck-header-bar-2 {
    padding: 10px 25px;
  }
  .housecheck-header-container > .housecheck-header-bar-3 {
    padding: 10px 25px;
  }
}

@media (max-width: 497px) {
  .housecheck-header-bar-1 > .header-right-btns-container {
    display: none;
  }
  .housecheck-header-bar-2 > .nav {
    display: none;
  }
  .housecheck-header-container > .housecheck-header-bar-1 p {
    font-size: 15px;
  }
}

@media (max-width: 320px) {
  .housecheck-header-container > .housecheck-header-bar-1 {
    padding: 6px 10px;
  }
  .housecheck-header-container > .housecheck-header-bar-2 {
    padding: 0 10px;
  }
  .housecheck-header-container > .housecheck-header-bar-3 {
    padding: 6px 10px;
  }
}

/* .rowstyle {
  padding: 8px;
  padding-left: 70px;
  color: white;
  font-weight: 300;
  font-size: 20px;
  text-align: center;
  height: 65px;
  overflow: hidden;
}

 .navlogo {
  margin-left: 45px;
  padding: 15px;
}
.navlist {
  list-style: none;
}
.navfirst {
  display: inline;
  padding: 16px;
  font-size: 17px;
}
.formhead {
  padding-left: 40px;
  margin-top: 35px;
}
.search-bar {
  display: inline-flex;
  border: 2px solid #ddd;
  padding: 0px;
  height: 46px;
  border-radius: 4px;
}
.form-control {
  height: 42px !important;
  width: 300px !important;
  border: none !important;
}
.form-ctl {
  color: #ddd !important;
}
.searchicon {
  width: 25px;
  height: 38px;
  border: none;
}
.searchicon {
  margin-top: 12px;
  margin-left: 7px;
}
.search-bar {
  display: inline-flex;
  border: 2px solid #ddd;
  padding: 0px;
  height: 46px;
  border-radius: 4px;
}
.form-ctl {
  color: #ddd !important;
}
.dropdown {
  position: relative;
  display: inline-block;
}
.dropbtn {
  background-color: #ffffff;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}
.dropdown:hover .dropbtn {
  background-color: #16a3b2;
}
.dropdown:hover .dropdown-content {
  display: block;
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: #ffffff;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.dropdown-content a:hover {
  background-color: #ddd;
} */
