:root {
  --primary-font: "Barlow", sans-serif;
  --theme-color-1: #cf1f24;
  --theme-color-2: #2d2d2d;
}

@font-face {
  font-family: "Barlow", sans-serif;
  src: local("MyFont"), url(./Fonts/Barlow-Regular.ttf) format("woff");
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container {
  width: 100%;
  box-sizing: border-box;
  flex-wrap: wrap;
  max-width: 100%;
  min-width: 100%;
  padding: 0 !important;
  margin: 0 !important;
}

.align-start {
  align-content: flex-start;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.btn-info {
  background-color: #6204b9 !important;
  border-color: #6204b9 !important;
  border-radius: 8px !important;
}

.app-page {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-color: #a9a9a9;
}

.app-box {
  border-radius: 30px;
  /*box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);*/
  padding: 20px;
  width: 100%;
  max-width: 350px;
  height: 70%;
  background-color: #ffffff;
}

.dropdown-menu.show {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 0;
}

.full-width {
  width: 100%;
}

.clickable {
  cursor: pointer;
}

.aff-loader-container {
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
