/*HEADER*/
.popUprelative {
  position: relative;
}
.rowstyle {
  padding: 8px;
  padding-left: 70px;
  color: white;
  font-weight: 300;
  font-size: 20px;
  text-align: center;
  margin: 0 !important;
}

/*HEADER*/
/*NAVBARLOGO*/
.row-menu {
  display: none !important;
}
.navlist {
  list-style: none;
}
.formhead {
  padding-left: 26px;
  margin: 10px !important;
}
.dropbtn {
  background-color: #ffffff;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: #ffffff;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #16a3b2;
}

.searchicon {
  width: 25px;
  height: 38px;
  border: none;
}
.search-bar {
  display: inline-flex;
  border: 2px solid #ddd;
  padding: 0px;
  height: 46px;
  border-radius: 4px;
}
.form-control {
  /*height: 42px !important;*/
  /*width: 300px !important;*/
  border: none !important;
}
.form-ctl {
  color: #ddd !important;
}
.searchicon {
  margin-top: 12px;
  margin-left: 7px;
}
/*NAVBARLOGO*/
/*MODAL*/
.service-request-bar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.service-request-bar-text {
  width: fit-content;
  margin: 0 auto;
  margin-right: calc(50% - 140px);
}
.service-request-bar-btns {
  width: fit-content;
  margin: 0 auto;
  margin-left: calc(50% - 140px);
}
.ThemeColor2 {
  /*background-color: #2d2d2d;*/
}
.service-request-bar-text-p {
  color: white;
  margin-top: 10px;
  font-weight: 300;
  font-size: 20px;
  margin: 0;
}
/* .logo1 {
  max-height: 100%;
  max-width: 100%;
  width: 150px;
  height: 150px;
  background-size: cover;
  background-image: url("/public/image/home.png");
  float: left;
} */
.textinside {
  padding-left: 20px;
}
.textbox-one {
  margin-top: 13px;
}
.service {
  padding: 0 16px;
  background-color: #ffffff;
  border: none;
  border-radius: 4px;
  color: #2f2f2f;
}
.login {
  padding: 0 16px;
  background-color: #ffffff;
  border: none;
  border-radius: 4px;
  color: #2f2f2f;
}
.log-in {
  margin-right: 6px;
  margin-right: 5px !important;
}
.contain {
  padding: 0px !important;
}
.home {
  width: 100%;
  display: flex;
}
.home-image {
  width: 100%;
}
.blue-side {
  width: 20%;
}
.img-floor {
  /*width:100%;*/
}
.img-footer {
  width: 100%;
  background-color: #e9e9e9;
}
.row-style {
  width: 100%;
  margin-left: 1px !important;
}
.modal-header {
  /* background-color: #51c5d2; */
}
.modal1 {
  font-size: 30px;
  font-weight: 700;
}
.modal-dialog {
  max-width: 603px !important;
  height: 603px;
  margin: 1.75rem auto;
  padding: 0;
}
.input1 {
  border: 2px solid #ddd;
  width: 100%;
  border-radius: 4px;
  padding: 5px;
  color: #ddd;
  margin-top: 12px;
}
.input2 {
  border: 2px solid #ddd;
  width: 100%;
  margin-top: 12px;
  margin-bottom: 15px;
  border-radius: 4px;
  padding: 5px;
  color: #ddd;
}
.input3 {
  border: 2px solid #ddd;
  border-radius: 4px;
  margin-top: 12px;
  padding: 5px;
  width: 50%;
  color: #ddd;
}
.select-type {
  border-radius: 4px;
  border: 2px solid #ddd;
  padding: 5px;
  width: 20%;
  background: none;
}
.zipcd {
  border-radius: 4px;
  border: 2px solid #ddd;
  padding: 5px;
  width: 28%;
  color: #ddd;
}
.propert-input {
  border-radius: 4px;
  border: 2px solid #ddd;
  padding: 5px;
  width: 100%;

  margin-top: 12px;
}
.radio-par {
  margin-top: 12px;
}
.list-in {
  width: 100%;
  border-radius: 4px;
  border: 2px solid #ddd;
  padding: 5px;
}
.modal-foot {
  margin-top: 40px;
  margin-bottom: 0;
  color: #ffffff;
  padding: 15px;
  border-radius: 5px;
  text-align: center;
}
/*MODAL*/
/*FOOTER*/
.icon1 {
  width: 23px;
  font-size: 17px !important;
  font-weight: 700;
  text-align: center;
}
.mob-footer {
  display: flex;
}
.mob-part {
  display: flex;
}
.phone {
  font-weight: 700;
  font-size: 15px;
}
.txt {
  font-size: 15px;
}
.footer-line {
  margin-top: 20px;
  color: #ffffff;
  padding: 20px;
  text-align: center;
  font-size: 12px;
}
.ThemeColor2 {
  /*background-color: #2d2d2d;*/
}
@media (max-width: 769px) {
  .container {
    padding: 0;
    margin: 0 !important;
  }
  .blue-side {
    display: none;
  }
  .rd {
    /*justify-content: space-around;*/
  }
  .navlist {
    position: relative;
  }
  /*.formhead{
		margin-top: 0px;
		width: 100%;
   		display: flex;
   	 	justify-content: flex-end;

	}*/
  .formhead {
    width: 24%;
    margin-top: 18px;
    padding-left: 0px;
  }
  .search-bar {
    width: 235px;
  }
}
@media (max-width: 768px) {
  .container {
    max-width: 100% !important;
    /*margin: 0 !important;*/
  }
  .search-bar {
    width: 260px;
  }
  .mob-footer {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }
  .icon1 {
    width: 35px;
    font-size: 25px !important;
    text-align: center;
  }
  .txt {
    font-size: 23px;
  }
  .phone {
    font-weight: 700;
    font-size: 22px;
  }
  .mob-part {
    display: flex;
    width: 100%;
  }
  .footer-line {
    margin-top: 20px;

    color: #ffffff;
    padding: 20px;
    text-align: center;
    font-size: 16px;
  }
  /* .ThemeColor2 {
    background-color: #2d2d2d;
  } */
  .service-request-bar-text {
    margin-right: auto;
  }
  .service-request-bar-btns {
    margin-left: auto;
  }
}
@media (max-width: 415px) {
  .rowstyle {
    padding: 8px;
    padding-left: 8px;
    color: white;
    font-weight: 300;
    font-size: 10px;
    text-align: center;
    /* display: flex !important; */
    word-break: break-word;
  }
  .form-control {
    width: 100% !important;
  }
  .rd {
    display: none !important;
    flex-flow: wrap;
    /*display: none;*/
  }
  .rd-in {
    display: flex;
  }
  .formhead {
    margin-top: 0px;
    margin: auto;
    padding-left: 0;
    margin-bottom: 10px;
  }
  .input-append {
    padding-left: 15px;
  }
  .textbox-one {
    margin-top: 10px;
    font-size: 9px;
  }
  .service-request-bar-text-p {
    font-size: 11px;
  }
  .service {
    padding: 3px 8px 3px 8px;
  }
  .blue-side {
    width: 20%;
    display: none;
  }
  .form-control {
    /*height: 24px !important;*/
  }

  .search-bar {
    width: 265px;
    height: 32px;
  }
  .searchicon {
    margin-top: 5px;
    margin-left: auto;
  }
  .row-menu {
    /*display: flow-root !important;*/
    /*display: block;*/
  }
  .select-menu {
    border: 2px solid #ddd;
    border-radius: 4px;
    color: #ddd;
  }
  .dropbtn {
    background-color: #16a3b2;
    margin-top: 3px;
    padding: 8px;
    color: #ffffff;
    border-radius: 4px;
    /*margin-left: 30px;*/
  }
  .dropdown-content {
    right: 0px;
    min-width: 25px;
    background-color: #16a3b2;
    color: #ffffff;
  }

  .modal-header {
    padding: 5px !important;
  }
  .modal1 {
    font-size: 15px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .input1 {
    padding: 0px;
    padding-left: 5px;
    font-size: 13px;
  }
  .input2 {
    padding-left: 5px !important;
    font-size: 13px;
    padding: 0px;
    margin-top: 5px;
  }
  .input3 {
    margin-top: 5px;
    padding: 1px;
    font-size: 14px;
    padding-left: 5px;
    width: 40%;
  }
  .zipcd {
    border-radius: 4px;
    border: 2px solid #ddd;
    padding: 2px;
    width: 26%;
    font-size: 13px;
    color: #ddd;
  }
  .select-type {
    border-radius: 4px;
    border: 2px solid #ddd;
    padding: 2px;
    width: 30%;
    padding-left: 2px;
    margin-top: 5px;
    font-size: 13px;
  }
  .propert-input {
    padding-left: 5px !important;
    font-size: 13px;
    padding: 2px;
    margin-top: 5px;
  }
  .radio-par {
    margin-top: 0px;
    font-size: 13px;
    margin-bottom: 0px;
  }
  .yes-radio {
    font-size: 13px !important;
  }
  .no-radio {
    font-size: 13 !important;
  }
  .affiliate-li {
    font-size: 13px;
    margin-bottom: 5px;
    padding: 4px;
  }
  .list-in {
    padding: 4px;
    font-size: 13px;
  }
  .modal-foot {
    margin-top: 5px;
    color: #ffffff;
    padding: 5px;
    font-weight: 500;
    font-size: 13px;
  }
  .icon1 {
    width: 0px;
    font-size: 12px !important;
  }
  .txt {
    font-size: 13px;
    margin-bottom: 5px;
  }
  .phone {
    font-size: 13px;
    margin-bottom: 2px;
  }
  .mob-part {
    margin-top: 10px;
    display: flex;
    width: 100%;
    justify-content: space-evenly;
  }
  .footer-line {
    margin-bottom: 20px;
    font-size: 10px;
    padding: 12px;
    margin-bottom: 0;
  }
  .mob-footer {
    display: flex;
    width: 100%;
  }
  .footer-contain {
    padding: 0 !important;
  }
  .login {
    font-size: 14px;
    padding: 3px 7px;
  }
  .mob-login {
    display: none;
  }
  .textbox-one {
    margin-top: 0 !important;
    display: flex;
    align-items: center;
  }
}
@media (max-width: 376px) {
  .search-bar {
    width: 230px;
    height: 30px;
  }
  .service {
    padding: 3px 8px 3px 8px;
  }
  .mob-footer {
    display: flex;
    justify-content: space-evenly;
  }
  .dropbtn {
    margin-left: 10px;
    background-color: #16a3b2;
    margin-top: 3px;
    padding: 6px;
    color: #ffffff;
    border-radius: 4px;
  }
}
@media (max-width: 361px) {
  .search-bar {
    width: 200px;
  }
}

@media (max-width: 320px) {
  .row-style,
  .rowstyle {
    margin-left: 0px !important;
    padding: 8px 0 8px 0px;
  }
  .container {
    padding: 0 !important;
  }
}
.account {
  text-decoration: underline;
  color: #cf2026;
}
.user {
  border: 2px solid #ddd;
  width: 100%;
  margin-top: 12px;
  border-radius: 4px;
  padding: 5px;
  /* color: #ddd; */
  background: none;
}
.forgot {
}
.fb {
  background-color: transparent;
  border: 1px solid;
  border-color: #51c5d2;
  border-radius: 5px;
  margin: 20px 5px 0 5px;
  font-size: 14px;
}
#dialog.modal-dialog {
  max-width: 438px !important;
}

.rdt .form-control {
  position: relative;
}
.calender {
  position: relative;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
}
.call-for-quote {
  width: 1110px !important;
}
.login-icon {
  /* color: #ffc641; */
}
.font-br {
  font-family: var(--primary-font);
}
