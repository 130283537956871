.txt a {
  color: #212529;
}
@media (max-width: 320px) {
  .container {
    padding: 0 !important;
  }
}
@media (max-width: 376px) {
  .part3-list {
    font-size: 13px;
  }
  .mob-footer {
    display: flex;
    justify-content: space-evenly;
  }
}
@media (max-width: 1024px) {
  .mob-part {
    display: none !important;
  }
  .footer-part4 {
    font-size: 13px;
  }
  .footer-line {
    margin-bottom: 20px;
    font-size: 10px;
    padding: 12px;
    margin-bottom: 0;
  }
  .part3-list {
    font-size: 13px;
  }
  .mob-part {
    margin-top: 10px;
    display: flex;
    width: 100%;
    justify-content: space-evenly;
  }
  .phone {
    font-size: 13px;
    margin-bottom: 2px;
  }
  .txt {
    font-size: 13px;
    margin-bottom: 5px;
  }
  .HouseCheck {
    font-size: 14px;
    margin-bottom: 2px;
  }
  .icon1 {
    width: 0;
    font-size: 12px !important;
  }
  .mob-footer {
    display: flex;
    width: 100%;
  }
  .mob-row {
    margin: 0 !important;
  }
  .footer-contain {
    padding: 0 !important;
  }
}
@media (max-width: 768px) {
  .footer-line {
    margin-top: 20px;
    color: #ffffff;
    padding: 20px;
    text-align: center;
    font-size: 16px;
  }
  .part3-list {
    font-size: 25px;
  }
  .txt {
    font-size: 23px;
  }
  .HouseCheck {
    font-weight: 700;
    font-size: 24px;
  }
  .icon1 {
    width: 35px;
    font-size: 25px !important;
    text-align: center;
  }
  .mob-footer {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }
  .container {
    max-width: 100% !important;
    /*margin: 0 !important;*/
  }
  .phone {
    font-weight: 700;
    font-size: 22px;
  }
  .mob-part {
    display: flex;
    width: 100%;
  }
}
@media (max-width: 769px) {
  .container {
    padding: 0;
    margin: 0 !important;
  }
}
.mob-footer {
  display: flex;
}
.footer-logo-container {
  width: fit-content;
}
.footer-logo {
  width: 100%;
  height: auto;
  /* object-fit: cover; */
}
.icon-group {
  margin-top: 35px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.icon-group a,
.icon-group a:hover {
  color: initial;
}
.icon1 {
  width: 23px;
  font-size: 17px !important;
  font-weight: 700;
  text-align: center;
}
.HouseCheck {
  font-weight: 700;
}
.txt {
  font-size: 15px;
}
.phone {
  font-weight: 700;
  font-size: 15px;
}
.mob-part {
  display: flex;
}
.footer-part3 {
  list-style: none;
  font-size: 15px;
}
.part3-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.footer-line {
  margin-top: 20px;
  color: #ffffff;
  padding: 20px;
  text-align: center;
  font-size: 12px;
}
.footer-part4 {
  list-style: none;
  font-size: 15px;
}

.housecheck-footer-container {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  padding: 40px 136px;
  border-top: 1px solid #ececf4;
}

.footer-opt {
  color: inherit;
  text-decoration: inherit;
  /* text-decoration: none; */
}

.footer-opt:hover {
  color: var(--theme-color-1);
  transition-property: color, background-color, border-color;
  transition-duration: 0.2s;
  transition-timing-function: linear;
}

@media (max-width: 1024px) {
  .housecheck-footer-container {
    padding: 40px 100px;
  }
}

@media (max-width: 768px) {
  .housecheck-footer-container {
    padding: 40px 50px;
  }
}

@media (max-width: 480px) {
  .housecheck-footer-container {
    padding: 40px 25px;
  }
  .foot-in {
    display: flex;
    justify-content: center;
  }
  .icon-group {
    margin-top: 20px;
    justify-content: center;
  }
}

@media (max-width: 320px) {
  .housecheck-footer-container {
    padding: 40px 10px;
  }
}
