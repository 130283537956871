.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  min-height: 100vh !important;
  z-index: 100;
  padding: 48px 0 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}
#sidebar-wrapper {
  min-height: 100vh !important;
  width: 100vw;
  margin-left: -1rem;
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
}
#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#page-content-wrapper {
  min-width: 0;
  width: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background: #ffffff;
}

.menu {
  background: #fff;
  opacity: 0;
  visibility: hidden;
  transition: all 0.25s ease;
  transform: translateX(-50%);
  height: 100%;
  width: 300px;
  position: fixed;
  z-index: 1;
  padding: 15px;
  height: 100%;
  width: 300px;
}

.side-bar-close .side-bar-menu {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1;
  background-color: hsla(120, 100%, 0%, 0.3);
}

.close-icon {
  display: flex;
  justify-content: flex-end;
  padding: 0 15px;
  font-size: 20px;
}

.close-icon > div {
  width: 30px;
  height: 30px;
  overflow: hidden;
}

.close-icon > div > span {
  font-size: 30px;
}

.active {
  opacity: 1;
  visibility: visible;
  transition: all 0.25s ease;
  transform: translateX(0);
  position: fixed;
  z-index: 1;
  padding: 15px 0px;
  height: 100%;
  width: 300px;
}

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    left: 20%;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}

.side-menu-drop-down {
  width: 100%;
  border-bottom: 1px solid #dedede;
  padding: 15px 0;
  margin-right: 40px;
}

.side-menu-drop-down li {
  font-size: 18px;
  padding: 0 40px;
}

.side-bar-menu-btn {
  height: 140px;
  display: flex;
  align-items: center;
  padding-left: 40px;
  margin-top: 40px;
  background: #fbfbfb;
}

.side-bar-login-btn {
  margin-left: -9px;
}

.side-bar-logo {
  display: flex;
  height: 200px;
  align-items: center;
  justify-content: center;
  background: #e0e0e0;
}

.side-bar-img-contain {
  width: 170px;
  height: auto;
}
