.form-error-bar {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 5px;
  background: rgba(255, 0, 0, 0.1);
  color: var(--theme-color-1);
}
